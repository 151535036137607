body {
	background-color: #e0e0e8;
}
/*
	Home.js (Generate secret screen)
*/
.form {
	background-color: #ffffff;
	text-align: center;
	width: 50%;
	align: center;
	margin: 4em 25%;
	border: 1px solid black;
	filter: drop-shadow(6px 7px 12px #888);
	text-align: left;
}

.formTitle {
	background-color: blue;
	color: white;
	padding-left: 12px;
	padding-top: 0.25em;
	padding-bottom: 0.25em;
	font-size: 2em;
}
.form h1 {
	font-size: 1.5em;	
	margin-left: 12px;
}

.form p {
	margin-left: 12px;
}

.form .buttonBar {
	display: flex;
	justify-content: flex-end;
	padding: 0.5em 0.5em 0.5em 0.5em;
}

.form button {
	background-color: blue;
	color: white;
	border: 0;
	font-size: 1em;
	padding: 0.5em 1em 0.5em 1em;
	margin-left: 0.5em;
}
.form .action {
	background-color: blue;
}
.form .secondary {
	background-color: #4a6;
}
.form label {
	display: inline-block;
	width: 200px;
	text-align: right;
}
.form hr {
	padding: 0;
	margin: 0 0 0 0;
	/* margin: 1em 2em 1em 2em; */
	color: #eee;
}
.form input {
	background-color: #ffffff;
	border: 0px;
	border-bottom: 1px solid #888;
	font-size: 1.5em;
	padding: 0.5em 0.5em 0.5em 0.5em;
}
.form input:focus {
	border: 0px;
	border-bottom: 1px solid black;
	outline: none;
}

.form select {
	background-color: #ffffff;
	border: 0px;
	font-size: 1.5em;
	padding: 0.5em 0.5em 0.5em 0.5em;
}

.submitted {
	background: #f0f0ff;
	border: 1px solid black;
    text-align: center;
    width: 50%;
    align: center;
    margin: 4em 25%;
    padding: 2em 5em 2em 5em;
}
.submitted input {
	background-color: transparent;
	border: 0px;
	font-size: 1.5em;
	padding: 0.5em 0.5em 0.5em 0.5em;
	width: 100%;
	text-align: center;
}
.submitted input:focus {
    outline: 0 none;
}

/*
	Secret.js (Retreive a secret)
*/
.secret {
    background-color: #f0f0ff;
    border: 1px solid black;
    text-align: center;
    width: 50%;
    align: center;
    margin: 4em 25%;
    padding: 2em 5em 2em 5em;
}

.secret input {
    background-color: #f0f0ff;
	border: 0px;
	font-size: 1.5em;
	padding: 0.5em 0.5em 0.5em 0.5em;
	text-align: center;
}

.secret input:focus {
    outline: 0 none;
}

.footer {
	background-color: grey;
	color: black;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-top: 1em;
	padding-bottom: 1em;
	border-top: 1px solid black;
}
.footer div {
	flex-grow: 4;
	text-align: center;
}

.footer div a {
	color: black;
	text-decoration: none;
}

.footer div a:visited {
}

.footer div a:hover {
	text-decoration: underline;
}
